import GaCategory from '@/entities/enums/GaCategory';
import { TrackingViewPropsCategory, TrackingViewPropsTitle } from '@/entities/enums/Tracking';
import Home from '@/front/containers/Home';
import { useThorPageview } from '@/front/hooks/useThorPageview';

const HomePage = () => {
    useThorPageview(TrackingViewPropsTitle.HOMEPAGE, TrackingViewPropsCategory.HOMEPAGE);

    return <Home />;
};

const GA_PAGE_TEMPLATE = 'index';
const GA_PAGE_CATEGORY = 'index';

const gaPageData = {
    templates: {
        contentGroup1: GA_PAGE_TEMPLATE,
        dimension4: GA_PAGE_TEMPLATE,
        contentGroup2: GA_PAGE_CATEGORY,
        dimension8: GA_PAGE_CATEGORY,
    },
    events: [
        {
            category: GaCategory.HOME_PAGE,
            action: 'estima_search_form:landed',
            label: 'home:estima_search_form',
            nonInteraction: true,
        },
    ],
};

const dataLayerPageData = {
    category: GA_PAGE_CATEGORY,
    template: GA_PAGE_TEMPLATE,
};

export async function getStaticProps() {
    return {
        props: {
            gaPageData,
            dataLayerPageData,
        },
    };
}

export default HomePage;
