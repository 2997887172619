import { useState } from 'react';
import { translate } from '@ma-js-common/translate';
import type { SearchItem } from '@ma-react/components';
import {
    Button,
    Grid,
    Link,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    Title,
} from '@ma-react/components';
import { ChevronRight } from '@ma-react/icons';
import { useThorEvent } from '@sl/react-thor';
import clsx from 'clsx';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';

import { AGENCY_COMPARE_PAGE_PREFIX } from '@/constants';
import GaCategory from '@/entities/enums/GaCategory';
import PlaceType from '@/entities/enums/PlaceType';
import HomeAgencyComparator from '@/front/assets/images/home/home-agency-comparateur@2x.jpg';
import HomeEstimaNew from '@/front/assets/images/home/home-estima-new@2x.jpg';
import HomeHeroApartment from '@/front/assets/images/home/home-hero-apartment_1930x570.jpg';
import HomePersonalConsultationFollow from '@/front/assets/images/home/home-personal-consultation-follow@1.5x.png';
import MakeSuccessfulSale from '@/front/assets/images/home/make-successful-sale@2x.jpg';
import HeroCard from '@/front/components/HeroCard';
import HeroSearchCard from '@/front/components/HeroSearchCard';
import LandingSection from '@/front/components/LandingSection';
import { useDispatcher } from '@/front/context/DispatcherContext';
import { gaEvent } from '@/front/helpers/analytics/Ga';
import useGaSendOnce from '@/front/hooks/useGaSendOnce';
import { useMedium } from '@/front/hooks/useMedium';
import useValuationOnSubmit from '@/front/hooks/useValuationOnSubmit';
import MarketingPriceIcon from '@/front/icons/MarketingPriceIcon';
import SoldPropertiesIcon from '@/front/icons/SoldPropertiesIcon';
import GetPlaceUri from '@/use-cases/client/GetPlaceUri';

import styles from '../Home/home.module.scss';

const GA_LABEL_PREFIX = 'home';
const GA_LABEL_HERO_FORM = `${GA_LABEL_PREFIX}:estima_search_form_hero`;

const SEOTitle = translate('Meilleurs Agents : N°1 des prix immobiliers et estimation en ligne');
const SEODescription = translate(
    'Obtenez gratuitement une estimation en ligne du prix de votre bien immobilier, puis engagez les meilleures agences immobilières partenaires de Meilleurs Agents pour vendre votre bien immobilier au meilleur prix.',
);
const FORM_PLACE_TYPES = [PlaceType.ADDRESS, PlaceType.STREET];

const GEO_SEARCH_PROPS = {
    withSearchIcon: false,
    buttonLabel: translate('Estimer'),
    allowEmptySubmit: true,
    showEmptyResultMessage: false,
};

const Home = () => {
    const [dispatcher] = useDispatcher();
    const router = useRouter();

    const { onSubmit, isLoading } = useValuationOnSubmit({ gaCategory: GaCategory.HOME_PAGE });

    const [currentIndex, setCurrentIndex] = useState(0);

    useMedium();

    const onSubmitRealtor = (searchItem?: SearchItem | null) => {
        if (!searchItem) return;

        gaEvent({
            category: GaCategory.HOME_PAGE,
            action: `go:all_pros:${searchItem.type}`,
            label: 'home:all_pros_search_form:with_suggestion',
        });

        const getPlaceUri = GetPlaceUri.INSTANCE;

        getPlaceUri
            .perform(
                AGENCY_COMPARE_PAGE_PREFIX,
                searchItem.id as number,
                searchItem.type,
                searchItem.slug,
            )
            .then((url) => {
                router.push(url);
            });
    };

    const [sendGaDataTabEstima] = useGaSendOnce({
        action: 'estima_search_form:landed',
        label: 'home:search_mask:estima_tab',
        category: GaCategory.HOME_PAGE,
    });
    const [sendGaDataTabPro] = useGaSendOnce({
        action: 'all_pros_search_form:landed',
        label: 'home:search_mask:compare_tab',
        category: GaCategory.HOME_PAGE,
    });
    const [sendGaDataTabQSL] = useGaSendOnce({
        action: 'homepage:search_block:qsl_landing_tab',
        label: 'home:search_mask:estimate_qsl_landing_tab',
        category: GaCategory.HOME_PAGE,
    });

    const eventClickProMa = useThorEvent({
        eventData: {
            event: 'outlink',
            link_url: 'https://pro.meilleursagents.com/',
            outbound: true,
            av_zone: 'bottom',
        },
    });

    const handleChange = (index: number) => {
        setCurrentIndex(index);
        switch (index) {
            case 0:
                sendGaDataTabEstima();
                break;
            case 1:
                sendGaDataTabPro();
                break;
            case 2:
                sendGaDataTabQSL();
                break;
            default:
                // nothing to do by default
                break;
        }
    };

    return (
        <>
            <NextSeo
                canonical="https://www.meilleursagents.com/"
                description={SEODescription}
                openGraph={{
                    title: SEOTitle,
                    description: SEODescription,
                    url: 'https://www.meilleursagents.com/',
                    images: [
                        {
                            url: 'https://www.meilleursagents.com/static/browser_icons/ms-icon-310x310.png?t=1627992977',
                            width: 310,
                            height: 310,
                            alt: 'Logo Meilleurs Agents',
                        },
                    ],
                }}
                title={SEOTitle}
            />
            <LandingSection className={styles['home-hero-mask--apartment']} withPadding={false}>
                <div className={styles['home-hero-background--wrap']}>
                    <Image
                        alt={translate('Tout commence par une bonne estimation')}
                        layout="fill"
                        objectFit="cover"
                        placeholder="blur"
                        quality={100}
                        src={HomeHeroApartment}
                    />
                </div>
                <Grid md={{ direction: 'column', align: 'center' }} container>
                    <Grid className={styles['home-hero-mask--container']} item>
                        <Title as="h1" className={styles['home-hero__title']} size="xxl">
                            {translate('Tout commence par une bonne estimation')}
                        </Title>

                        <div className={styles['home-hero__search']} id="home-hero-search">
                            <Tabs onChange={handleChange}>
                                <TabList>
                                    <Tab index={0} data-tabs-nav-estima>
                                        {dispatcher.www_next_home_qsl_promote ? (
                                            <>
                                                <span className="hide-sm">
                                                    {translate('Estimer un bien')}
                                                </span>
                                                <span className="hide-from-md">
                                                    {translate('Estimer')}
                                                </span>
                                            </>
                                        ) : (
                                            translate('Estimer un bien')
                                        )}
                                    </Tab>
                                    {dispatcher.www_next_home_qsl_promote && (
                                        <Tab index={2} data-tabs-nav-qsl>
                                            <span className="hide-sm">
                                                {translate('Vendre un bien')}
                                            </span>
                                            <span className="hide-from-md">
                                                {translate('Vendre')}
                                            </span>
                                        </Tab>
                                    )}
                                    <Tab index={1} data-tabs-nav-realtors>
                                        {translate('Comparer des agences')}
                                    </Tab>
                                </TabList>
                                <TabPanels className={styles['home-hero__tab-panels']}>
                                    <TabPanel index={0}>
                                        {currentIndex === 0 ? (
                                            <HeroSearchCard
                                                className={styles['home-hero__hero-card']}
                                                gaData={{
                                                    actionPrefix: 'estima_search_form',
                                                    label: GA_LABEL_HERO_FORM,
                                                    category: GaCategory.HOME_PAGE,
                                                }}
                                                isLoading={isLoading}
                                                label={translate('Adresse du bien immobilier')}
                                                placeTypes={FORM_PLACE_TYPES}
                                                placeholder={translate(
                                                    '10 place du Marché, 92100 Boulogne-Billancourt',
                                                )}
                                                searchProps={{
                                                    ...GEO_SEARCH_PROPS,
                                                    idPrefix: 'home-estima-search-hero',
                                                }}
                                                onSubmit={(searchItem) =>
                                                    onSubmit({
                                                        searchItem,
                                                        gaLabelPrefix: GA_LABEL_HERO_FORM,
                                                    })
                                                }
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </TabPanel>
                                    {dispatcher.www_next_home_qsl_promote && (
                                        <TabPanel index={2}>
                                            {currentIndex === 2 ? (
                                                <HeroCard
                                                    className={clsx(
                                                        styles['home-hero__hero-card'],
                                                        'text--center',
                                                    )}
                                                >
                                                    <Text className="padding-top-double-sm">
                                                        {translate(
                                                            "Vendez vite et au bon prix avec notre service d'aide à la vente.",
                                                        )}
                                                    </Text>
                                                    <Button
                                                        as="a"
                                                        className="margin-top-double"
                                                        data-ua-event-action="go:estimate_qsl_landing"
                                                        data-ua-event-category={
                                                            GaCategory.HOME_PAGE
                                                        }
                                                        data-ua-event-label={`${GA_LABEL_PREFIX}:main:search_block:appointment_ma:button`}
                                                        data-ua-hit-type="event"
                                                        href="/rdv-meilleurs-agents/"
                                                        size="large"
                                                    >
                                                        {translate(
                                                            'Découvrir RDV Meilleurs Agents',
                                                        )}
                                                    </Button>
                                                </HeroCard>
                                            ) : null}
                                        </TabPanel>
                                    )}
                                    <TabPanel index={1}>
                                        {currentIndex === 1 ? (
                                            <HeroSearchCard
                                                className={styles['home-hero__hero-card']}
                                                gaData={{
                                                    actionPrefix: 'all_pros_search_form',
                                                    label: 'home:all_pros_search_form',
                                                    category: GaCategory.HOME_PAGE,
                                                }}
                                                label={translate('Ville ou code postal')}
                                                placeTypes={[
                                                    PlaceType.SUBREGION,
                                                    PlaceType.CITY,
                                                    PlaceType.SUBCITY,
                                                    PlaceType.ARRMUN,
                                                ]}
                                                placeholder={translate(
                                                    'Boulogne-Billancourt ou 92100',
                                                )}
                                                searchProps={{
                                                    withSearchIcon: false,
                                                    idPrefix: 'home-realtor-search-hero',
                                                    buttonLabel: translate('Comparer'),
                                                }}
                                                onSubmit={onSubmitRealtor}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        </div>
                    </Grid>
                </Grid>
            </LandingSection>

            <LandingSection backgroundColor="grey">
                <Grid className={styles['home-grid-container']} xs={{ align: 'center' }} container>
                    <Grid
                        lg={{ size: 4, order: 'first', offset: 1 }}
                        md={{ size: 5, order: 'first', offset: 1 }}
                        xs={{ size: 12, order: 'first' }}
                        item
                    >
                        <Title as="h2" className={styles['home-data__title']} size="xl">
                            {/* Unbreakable space (macOS: Alt+Space) is intentionaly used here */}
                            {translate('Vous vendez votre bien ?')}
                        </Title>

                        <Text as="p" className={styles['home-data__text']} size="m">
                            {translate(
                                'Un conseiller Meilleurs Agents vous accompagne gratuitement pour vendre vite et bien.',
                            )}
                        </Text>
                        <Button
                            as="a"
                            className={styles['home-secondary__link']}
                            data-ua-event-action="go:estimate_qsl_landing"
                            data-ua-event-category={GaCategory.HOME_PAGE}
                            data-ua-event-label={`${GA_LABEL_PREFIX}:main:appointment_ma:button`}
                            data-ua-hit-type="event"
                            data-medium="homepage_discover_meilleurs_agents_rdv_estimate_qsl_landing"
                            href="/rdv-meilleurs-agents/"
                            size="large"
                            variant="outlined"
                        >
                            {translate('Découvrir RDV Meilleurs Agents')}
                        </Button>
                    </Grid>
                    <Grid
                        lg={{ size: 5, order: 'last', offset: 1 }}
                        md={{ size: 4, order: 'last', offset: 1 }}
                        xs={{ size: 12, order: 'last' }}
                        item
                    >
                        <Image
                            alt={translate('Découvrir RDV Meilleurs Agents')}
                            className={styles['home-img__block']}
                            quality={100}
                            src={HomePersonalConsultationFollow}
                        />
                    </Grid>
                </Grid>
            </LandingSection>

            <LandingSection>
                <Grid className={styles['home-grid-container']} xs={{ align: 'center' }} container>
                    <Grid
                        lg={{ size: 4, order: 'last', offset: 1 }}
                        md={{ size: 5, order: 'last', offset: 1 }}
                        xs={{ size: 12, order: 'first' }}
                        item
                    >
                        <Title as="h2" className={styles['home-data__title']} size="xl">
                            {translate('Estimation immobilière en ligne')}
                        </Title>

                        <Text as="p" className={styles['home-data__text']} size="m">
                            <Link
                                data-ua-event-action="go:estima_landing_house"
                                data-ua-event-category={GaCategory.HOME_PAGE}
                                data-ua-event-label={`${GA_LABEL_PREFIX}:main:estima:links_to_other_estima_landings`}
                                data-ua-hit-type="event"
                                href="/estimation-immobiliere/maison/"
                            >
                                {translate('Estimez une maison')}
                            </Link>{' '}
                            {translate('ou')}{' '}
                            <Link
                                data-ua-event-action="go:estima_landing_apartment"
                                data-ua-event-category={GaCategory.HOME_PAGE}
                                data-ua-event-label={`${GA_LABEL_PREFIX}:main:estima:links_to_other_estima_landings`}
                                data-ua-hit-type="event"
                                href="/estimation-immobiliere/appartement/"
                            >
                                {translate('estimez un appartement')}
                            </Link>{' '}
                            {translate(
                                'gratuitement à partir de son adresse et de ses caractéristiques, pour découvrir immédiatement son prix et une',
                            )}{' '}
                            <Link
                                data-ua-event-action="go:estima_landing_rental"
                                data-ua-event-category={GaCategory.HOME_PAGE}
                                data-ua-event-label={`${GA_LABEL_PREFIX}:main:estima:links_to_other_estima_landings`}
                                data-ua-hit-type="event"
                                href="/estimation-immobiliere/loyer/"
                            >
                                {translate('estimation du loyer')}
                            </Link>{' '}
                            {translate(
                                'potentiel. Les estimations sont mises à jour tous les mois.',
                            )}
                        </Text>
                        <Button
                            as="a"
                            className={styles['home-secondary__link']}
                            data-ua-event-action="go:estima_landing_generic"
                            data-ua-event-category={GaCategory.HOME_PAGE}
                            data-ua-event-label={`${GA_LABEL_PREFIX}:main:estima:button`}
                            data-ua-hit-type="event"
                            href="/estimation-immobiliere/"
                            size="large"
                            variant="outlined"
                        >
                            {translate('Estimer un bien immobilier')}
                        </Button>
                    </Grid>
                    <Grid
                        lg={{ size: 5, order: 'first', offset: 1 }}
                        md={{ size: 4, order: 'first', offset: 1 }}
                        xs={{ size: 12, order: 'last' }}
                        item
                    >
                        <Image
                            alt={translate('Estimation en ligne immobilière')}
                            className={styles['home-img__block']}
                            quality={100}
                            src={HomeEstimaNew}
                        />
                    </Grid>
                </Grid>
            </LandingSection>

            <LandingSection backgroundColor="grey">
                <Grid className={styles['home-grid-container']} xs={{ align: 'center' }} container>
                    <Grid
                        lg={{ size: 5, order: 'last', offset: 1 }}
                        md={{ size: 4, order: 'last', offset: 1 }}
                        xs={{ size: 12, order: 'last' }}
                        item
                    >
                        <Image
                            alt={translate("Comparateur d'agences")}
                            className={styles['home-img__block']}
                            quality={100}
                            src={HomeAgencyComparator}
                        />
                    </Grid>
                    <Grid
                        lg={{ size: 4, order: 'first', offset: 1 }}
                        md={{ size: 5, order: 'first', offset: 1 }}
                        xs={{ size: 12, order: 'first' }}
                        item
                    >
                        <Title as="h2" className={styles['home-data__title']} size="xl">
                            {translate("Comparateur d'agences")}
                        </Title>

                        <Text as="p" className={styles['home-data__text']} size="m">
                            {translate(
                                'Nous comparons les agences locales pour vous permettre de choisir les meilleures agences pour votre projet, selon leurs ventes récentes et leurs avis clients.',
                            )}
                        </Text>
                        <Button
                            as="a"
                            className={styles['home-secondary__link']}
                            data-ua-event-action="go:compare"
                            data-ua-event-category={GaCategory.HOME_PAGE}
                            data-ua-event-label={`${GA_LABEL_PREFIX}:main:compare:button`}
                            data-ua-hit-type="event"
                            href="/professionnel-immobilier/"
                            size="large"
                            variant="outlined"
                        >
                            {translate('Trouver la bonne agence')}
                        </Button>
                    </Grid>
                </Grid>
            </LandingSection>

            <LandingSection>
                <Grid className={styles['home-grid-container']} xs={{ align: 'center' }} container>
                    <Grid
                        className={styles['home-block--center']}
                        md={{ size: 4, offset: 2 }}
                        xs={{ size: 12 }}
                        item
                    >
                        <MarketingPriceIcon />
                        <Title
                            as="h2"
                            className={styles['home-data__title']}
                            size="l"
                            noResponsiveResize
                        >
                            {translate('Carte des prix immobilier')}
                        </Title>
                        <Text as="p" className={styles['home-data__text']} size="m">
                            {translate('Découvrez les prix au m² autour de vous')}
                        </Text>
                        <Text as="p" className={styles['home-data__text']} size="m">
                            <Link
                                data-ua-event-action="go:prices"
                                data-ua-event-category={GaCategory.HOME_PAGE}
                                data-ua-event-label={`${GA_LABEL_PREFIX}:main:prices:button`}
                                data-ua-hit-type="event"
                                data-medium="homepage_see_price_map_price_map"
                                endIcon={<ChevronRight />}
                                href="/prix-immobilier/"
                            >
                                {translate('Voir la carte des prix M2')}
                            </Link>
                        </Text>
                    </Grid>
                    <Grid
                        className={styles['home-block--center']}
                        md={{ size: 4 }}
                        xs={{ size: 12 }}
                        item
                    >
                        <SoldPropertiesIcon height={67} />
                        <Title
                            as="h2"
                            className={styles['home-data__title']}
                            size="l"
                            noResponsiveResize
                        >
                            {translate('Historique des ventes')}
                        </Title>
                        <Text as="p" className={styles['home-data__text']} size="m">
                            {translate('Comparez les prix des ventes réalisées')}
                        </Text>
                        <Text as="p" className={styles['home-data__text']} size="m">
                            <Link
                                data-ua-event-action="go:dvf_landing"
                                data-ua-event-category={GaCategory.HOME_PAGE}
                                data-ua-event-label={`${GA_LABEL_PREFIX}:main:dvf:button`}
                                data-ua-hit-type="event"
                                data-medium="homepage_explore_dvf_sales_sold_properties_government"
                                endIcon={<ChevronRight />}
                                href="/prix-immobilier/dvf/"
                            >
                                {translate('Explorer les ventes DVF')}
                            </Link>
                        </Text>
                    </Grid>
                </Grid>
            </LandingSection>

            <LandingSection backgroundColor="grey">
                <Grid className={styles['home-grid-container']} xs={{ align: 'center' }} container>
                    <Grid
                        lg={{ size: 5, order: 'first', offset: 1 }}
                        md={{ size: 4, order: 'first', offset: 1 }}
                        xs={{ size: 12, order: 'last' }}
                        item
                    >
                        <div className={styles['home-data--illustration']}>
                            <Image
                                alt={translate('Comment réussir votre vente ?')}
                                className={styles['home-img__block']}
                                quality={100}
                                src={MakeSuccessfulSale}
                            />
                        </div>
                    </Grid>
                    <Grid
                        lg={{ size: 4, order: 'last', offset: 1 }}
                        md={{ size: 5, order: 'last', offset: 1 }}
                        xs={{ size: 12, order: 'first' }}
                        item
                    >
                        <Title as="h2" className={styles['home-data__title']} size="xl">
                            {translate('Comment réussir son projet immobilier ?')}
                        </Title>

                        <Text as="p" className={styles['home-data__text']} size="m">
                            {translate(
                                "Plongez dans notre blog où vous trouverez une multitude de conseils et d'outils indispensables pour concrétiser votre projet immobilier. Des astuces sur les prix, des recommandations sur les agences immobilières, et bien plus encore.",
                            )}
                        </Text>
                        <Link
                            data-ua-event-action="go:how_to_sell"
                            data-ua-event-category={GaCategory.HOME_PAGE}
                            data-ua-event-label={`${GA_LABEL_PREFIX}:main:how_to_sell:button`}
                            data-ua-hit-type="event"
                            endIcon={<ChevronRight />}
                            href="https://edito.meilleursagents.com/"
                        >
                            {translate('Découvrez tous nos conseils')}
                        </Link>
                    </Grid>
                </Grid>
            </LandingSection>

            <LandingSection
                className={styles['home-realtor-card']}
                withPadding={false}
                data-search-form-bottom-section
            >
                <Grid className={styles['home-grid-container']} container>
                    <Grid
                        className="text--center"
                        md={{ size: 8, offset: 2 }}
                        xl={{ size: 6, offset: 3 }}
                        xs={12}
                        item
                    >
                        <Title as="h2" className="margin-bottom-double text--center" size="xl">
                            {translate('Vous êtes agent immobilier ?')}
                        </Title>
                        <Text
                            className="margin-bottom-double text--center"
                            color="neutral-70"
                            medium
                        >
                            {translate(
                                '12 000 agences partenaires nous font déjà confiance, rejoignez-nous !',
                            )}
                        </Text>
                        <Button
                            as="a"
                            className={styles['home-secondary__link']}
                            data-ua-event-action="go:ma_pro"
                            data-ua-event-category={GaCategory.HOME_PAGE}
                            data-ua-event-label={`${GA_LABEL_PREFIX}:ma_pro:button`}
                            data-ua-hit-type="event"
                            href="https://pro.meilleursagents.com/?utm_source=trappe_lead&utm_medium=www&utm_campaign=Home&utm_content=home%3Ama_pro%3Abutton"
                            onClick={() => eventClickProMa()}
                            size="large"
                            variant="outlined"
                        >
                            {translate('Découvrez nos offres pro')}
                        </Button>
                    </Grid>
                </Grid>
            </LandingSection>
        </>
    );
};

export default Home;
