import type PlaceType from '@/entities/enums/PlaceType';
import PlaceRepository from '@/entities/repositories/PlaceRepository';
import type { PlaceParents } from '@/entities/types/PlaceParents';

class GetParentsByTypeAndId {
    static INSTANCE = new GetParentsByTypeAndId();
    private readonly placeRepository: PlaceRepository;

    constructor(placeRepository: PlaceRepository = PlaceRepository.INSTANCE) {
        this.placeRepository = placeRepository;
    }

    perform(type: PlaceType, id: number | string): Promise<PlaceParents> {
        return this.placeRepository.getParentsByTypeAndId(type, Number(id));
    }
}

export default GetParentsByTypeAndId;
