import { useEffect } from 'react';

export const useMedium = () => {
    useEffect(() => {
        if (!window.isUser) return;

        const links: NodeListOf<HTMLLinkElement> = document.querySelectorAll('a[data-medium]');
        links.forEach((link) => {
            const mediumParam = `m=${link.getAttribute('data-medium')}`;
            link.href = link.href.concat(
                link.href.includes('?') ? `&${mediumParam}` : `?${mediumParam}`,
            );
        });
    }, []);
};
