import type { SVGProps } from 'react';

const SoldPropertiesIcon = ({ width = 73, height = 73, ...props }: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            height={height}
            viewBox="0 0 73 73"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g fill="none" fillRule="evenodd">
                <path
                    d="M21.765 31.413c-.5 0-.97-.194-1.323-.547l-6.998-7c-.448-.446-.448-1.17 0-1.616.446-.446 1.17-.446 1.616 0l6.704 6.704 17.912-17.96c.447-.446 1.17-.447 1.617-.001.447.446.449 1.17.002 1.616L23.086 30.865c-.352.354-.822.548-1.321.548"
                    fill="#1E91FF"
                    transform="translate(-98 -623) translate(98 624) translate(.945) translate(9 6)"
                />
                <g transform="translate(-98 -623) translate(98 624) translate(.945) translate(9 6) translate(0 .158)">
                    <path
                        d="M52.638 36.02c0 2.104-1.712 3.817-3.816 3.817H5.916c-2.104 0-3.816-1.713-3.816-3.817V6.758c0-2.104 1.712-3.817 3.817-3.817H48.82c2.105 0 3.817 1.713 3.817 3.817V36.02zM48.822.843H5.916C2.662.842 0 3.504 0 6.758V36.02c0 3.255 2.664 5.917 5.917 5.917H26.32v16.014c0 .58.468 1.048 1.049 1.048.58 0 1.05-.468 1.05-1.049V41.938H48.82c3.255 0 5.918-2.662 5.918-5.916V6.757c0-3.253-2.663-5.917-5.917-5.917z"
                        fill="#1E91FF"
                    />
                </g>
            </g>
        </svg>
    );
};

export default SoldPropertiesIcon;
