import PlaceType from '@/entities/enums/PlaceType';
import type Place from '@/entities/types/Place';

interface GetValuationUrlParams {
    place?: Place;
    coordinates?: { lat: number; lng: number };
}

const getValuationFormUrlParams = ({
    place,
    coordinates,
}: GetValuationUrlParams = {}): URLSearchParams => {
    const valuationFormUrlParams = new URLSearchParams();

    const parents = place?.parents;
    const streetName =
        place?.type === PlaceType.STREET ? place.name?.full : parents?.street?.name?.full;

    const addressName =
        place?.type === PlaceType.ADDRESS ? `${place?.name?.full} ${streetName}` : streetName;

    const arrmun = place?.type === PlaceType.ARRMUN ? place : parents?.[PlaceType.ARRMUN];
    const city = place?.type === PlaceType.CITY ? place : parents?.[PlaceType.CITY];
    const subcity = place?.type === PlaceType.SUBCITY ? place : parents?.[PlaceType.SUBCITY];
    const asCity = arrmun || subcity || city;

    valuationFormUrlParams.set('item_city_id', asCity?.id.toString() || '');
    valuationFormUrlParams.set('item_zip', asCity?.postalCode || '');
    valuationFormUrlParams.set('item_city_name', asCity?.name?.full || '');

    valuationFormUrlParams.set('item_address', addressName || '');
    valuationFormUrlParams.set('address_lat', coordinates?.lat?.toString() || '');
    valuationFormUrlParams.set('address_lng', coordinates?.lng?.toString() || '');

    valuationFormUrlParams.set('action', 'prefill');

    return valuationFormUrlParams;
};

export default getValuationFormUrlParams;
