import type { SVGProps } from 'react';

const MarketingPriceIcon = ({ width = 63, height = 67, ...props }: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            fill="none"
            height={height}
            viewBox="0 0 63 67"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M28.6242 7.03857H8.50024C4.64924 7.03857 1.50024 10.1886 1.50024 14.0386V58.1946C1.50024 62.0446 4.64924 65.1946 8.50024 65.1946H52.6552C56.5062 65.1946 59.6552 62.0446 59.6552 58.1946V35.1666"
                stroke="#1E91FF"
                strokeLinecap="round"
                strokeWidth="2"
            />
            <path
                clipRule="evenodd"
                d="M51.4271 17.2183C51.1811 17.1193 50.9111 17.1253 50.6701 17.2343C50.4271 17.3433 50.2431 17.5433 50.1531 17.7963C49.8771 18.5653 49.4491 19.1773 48.8821 19.6163C48.1991 20.1463 47.4161 20.4143 46.5561 20.4143C45.4421 20.4143 44.5071 20.0673 43.7761 19.3833C43.1281 18.7783 42.6501 17.9293 42.3511 16.8603H46.1551C46.5351 16.8603 46.8771 16.6203 47.0051 16.2613C47.1041 15.9863 47.0611 15.6783 46.8931 15.4373C46.7241 15.1983 46.4481 15.0553 46.1551 15.0553H42.0611C42.0401 14.7743 42.0311 14.4793 42.0311 14.1783C42.0311 13.9983 42.0371 13.7643 42.0521 13.4813H47.0551C47.4361 13.4813 47.7781 13.2403 47.9061 12.8833C48.0041 12.6063 47.9621 12.2983 47.7931 12.0593C47.6241 11.8203 47.3491 11.6763 47.0551 11.6763H42.3141C42.5921 10.5603 43.0731 9.67029 43.7441 9.02729C44.5081 8.29629 45.4351 7.94229 46.5781 7.94229C47.4441 7.94229 48.1941 8.16128 48.8071 8.59429C49.2851 8.93328 49.6581 9.40129 49.9181 9.98529C50.0301 10.2363 50.2411 10.4313 50.4981 10.5213C50.7561 10.6103 51.0301 10.5923 51.2691 10.4663C51.7301 10.2253 51.9231 9.66629 51.7091 9.19328C51.2951 8.27529 50.6781 7.54229 49.8761 7.01629C48.9391 6.40229 47.7981 6.09229 46.4891 6.09229C44.8051 6.09229 43.3981 6.62429 42.3051 7.67629C41.3001 8.64329 40.6171 9.98929 40.2761 11.6763H39.1231C38.6261 11.6763 38.2211 12.0813 38.2211 12.5783C38.2211 13.0763 38.6261 13.4813 39.1231 13.4813H40.0661C40.0521 13.7593 40.0451 13.9923 40.0451 14.1783C40.0451 14.4803 40.0551 14.7753 40.0731 15.0553H39.1231C38.6261 15.0553 38.2211 15.4603 38.2211 15.9583C38.2211 16.4553 38.6261 16.8603 39.1231 16.8603H40.3031C40.6591 18.4913 41.3501 19.7923 42.3551 20.7303C43.4461 21.7483 44.8521 22.2653 46.5321 22.2653C47.8221 22.2653 48.9941 21.8803 50.0201 21.1223C50.9081 20.4643 51.5661 19.5693 51.9741 18.4603C52.1571 17.9613 51.9171 17.4163 51.4271 17.2183Z"
                fill="#1E91FF"
                fillRule="evenodd"
            />
            <path
                d="M42.1926 45.7515H50.2156"
                stroke="#1E91FF"
                strokeLinecap="round"
                strokeWidth="2"
            />
            <path
                clipRule="evenodd"
                d="M45.3615 43.422C45.5585 43.689 45.8725 43.846 46.2045 43.846C46.5375 43.846 46.8495 43.689 47.0485 43.422C47.0835 43.374 50.6305 38.588 54.1275 32.913C58.8935 25.178 61.3105 19.524 61.3105 16.107C61.3105 7.777 54.5345 1 46.2045 1C37.8755 1 31.0975 7.777 31.0975 16.107C31.0975 19.524 33.5155 25.178 38.2825 32.913C41.7795 38.588 45.3255 43.374 45.3615 43.422Z"
                fillRule="evenodd"
                stroke="#1E91FF"
                strokeLinecap="round"
                strokeWidth="2"
            />
        </svg>
    );
};

export default MarketingPriceIcon;
