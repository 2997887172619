import PlaceType from '@/entities/enums/PlaceType';
import PlaceRepository from '@/entities/repositories/PlaceRepository';

class GetPlaceUri {
    static INSTANCE = new GetPlaceUri();
    private readonly placeRepository: PlaceRepository;

    constructor(placeRepository: PlaceRepository = PlaceRepository.INSTANCE) {
        this.placeRepository = placeRepository;
    }

    private async getCitySlug(placeType: PlaceType, id: number): Promise<string> {
        const parents = await this.placeRepository.getParentsByTypeAndId(placeType, id);
        return parents[PlaceType.CITY]!.slug!;
    }

    private async getCitySlugStreetSlugAndId(
        placeType: PlaceType,
        id: number,
    ): Promise<{
        citySlug: string;
        streetSlug: string;
        streetId: number;
    }> {
        const parents = await this.placeRepository.getParentsByTypeAndId(placeType, id);
        const citySlug = parents[PlaceType.CITY]!.slug!;
        const streetSlug = parents[PlaceType.STREET]!.slug!;
        const streetId = parents[PlaceType.STREET]!.id!;
        return { citySlug, streetSlug, streetId };
    }

    async perform(
        prefix: string,
        placeId: number,
        placeType: PlaceType,
        placeSlug: string | undefined,
    ): Promise<string> {
        if (![PlaceType.STREET, PlaceType.BOROUGH, PlaceType.ADDRESS].includes(placeType)) {
            return Promise.resolve(`/${prefix}/${placeSlug}/`);
        }

        switch (placeType) {
            case PlaceType.STREET:
                // street → <city_slug>/<street_slug>-<street_id>
                return `/${prefix}/${await this.getCitySlug(
                    placeType,
                    placeId,
                )}/${placeSlug}-${placeId}/`;
            case PlaceType.BOROUGH:
                // borough → <city_slug>/quartier_<borough_slug>-<borough_id>
                return `/${prefix}/${await this.getCitySlug(
                    placeType,
                    placeId,
                )}/quartier_${placeSlug}-${placeId}/`;
            case PlaceType.ADDRESS:
                // adresse → <city_slug>/<street_slug>-<street_id>/<address_slug>
                // eslint-disable-next-line no-case-declarations
                const { citySlug, streetSlug, streetId } = await this.getCitySlugStreetSlugAndId(
                    placeType,
                    placeId,
                );
                // Slug on addresses is optionnal
                // Example /prix-immobilier/valenton-94460/les-vignes-2021877/
                return placeSlug
                    ? `/${prefix}/${citySlug}/${streetSlug}-${streetId}/${placeSlug}/`
                    : `/${prefix}/${citySlug}/${streetSlug}-${streetId}/`;
            default:
                // arrmun → <arrmun_slug>
                // city → <city_slug>
                // subregion → <subregion_slug>
                // region → <region_slug>
                return `/${prefix}/${placeSlug}/`;
        }
    }
}

export default GetPlaceUri;
