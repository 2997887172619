import { useState } from 'react';
import type { SearchItem } from '@ma-react/components';
import { useRouter } from 'next/router';

import GaCategory from '@/entities/enums/GaCategory';
import { TrackingViewPropsTitle } from '@/entities/enums/Tracking';
import { gaEvent } from '@/front/helpers/analytics/Ga';
import getValuationFormUrlParams from '@/front/helpers/valuation/getValuationFormUrlParams';
import GetParentsByTypeAndId from '@/use-cases/client/GetParentsByTypeAndId';

interface UseValuationOnSubmit {
    gaCategory?: GaCategory.VALUATION_PAGE | GaCategory.HOME_PAGE | GaCategory.EXPERTISE_PAGE;
    thorPageTitle?: TrackingViewPropsTitle;
}

interface ValuationOnSubmit {
    searchItem?: SearchItem | null;
    gaLabelPrefix?: string;
}

const referrerToSet = {
    Home: 'homepage',
    'Landing Estima': 'estima_landing',
    'Expertise data': 'about_expertise_data',
};

const useValuationOnSubmit = ({
    gaCategory = GaCategory.VALUATION_PAGE,
    thorPageTitle,
}: UseValuationOnSubmit = {}) => {
    const {
        query: { token },
    } = useRouter();
    const getParentsByTypeAndId = GetParentsByTypeAndId.INSTANCE;
    const [isLoading, setIsLoading] = useState(false);

    return {
        onSubmit: ({ searchItem, gaLabelPrefix }: ValuationOnSubmit = {}) => {
            setIsLoading(true);
            const hitCallback = async () => {
                let queryParams = new URLSearchParams();

                if (searchItem) {
                    const parents = await getParentsByTypeAndId.perform(
                        searchItem.type,
                        searchItem.id,
                    );
                    const place = {
                        id: Number(searchItem.id),
                        type: searchItem.type,
                        name: {
                            full: searchItem.name,
                        },
                        parents,
                    };

                    queryParams = getValuationFormUrlParams({ place });
                }

                queryParams.set('action', 'prefill');
                queryParams.set('cta_id', 'estima_form:hero_action_bar:estimate_button');
                queryParams.set('referrer', referrerToSet[gaCategory]);

                if (token) {
                    queryParams.set('token', `${token}`);
                }

                if (window.isUser) {
                    const pageTitle = thorPageTitle ? thorPageTitle : '';
                    const cta = searchItem
                        ? 'estima_search_with_suggestion'
                        : 'estima_search_without_suggestion';
                    queryParams.set('m', `${pageTitle}_${cta}_estimate_step0_adress`);
                }

                // We don't use Next router because it's an external redirect (recommended by Next docs)
                window.location.assign(`/estimation-immobiliere/form?${queryParams.toString()}`);
                setIsLoading(false);
            };

            gaEvent(
                {
                    category: gaCategory,
                    label: `${gaLabelPrefix}:${
                        searchItem ? 'with_suggestion' : 'without_suggestion'
                    }`,
                    action: `go:estima_form`,
                },
                hitCallback,
            );
        },
        isLoading: isLoading,
    };
};

export default useValuationOnSubmit;
